import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Feedback from '../components/InteractivePart/Page2/Feedback/index';
import HomeEquity from '../components/InteractivePart/Page2/HomeEquity';
import Page11 from "../components/InteractivePart/Page11/index"
function page11() {
  return (
    <main>
      <Header className="mx-auto "/>
      <Page11 />
      <div className='w-full bg-white'>
       <div className='container mx-auto'>
      {/* <HomeEquity/> */}
      <Feedback isEquityLoansHeloc={true}/>
      </div>
      </div>
      <Footer className="mx-auto"/>
    </main>
  )
}

export default page11
