import React from 'react'
import backButton from ".././../../../assets/backButton.svg";

interface BackButtonProps{
    setopenComponent: (value: React.SetStateAction<number>) => void;
    id:number;
}

function BackButton({setopenComponent, id}:BackButtonProps) {
    return (
        <div className='ml-2 w-10' onClick={()=>setopenComponent(id)}>
            <img src={backButton} alt="" className="w-10  cursor-pointer" />
        </div>
    )
}

export default BackButton;