import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { textanimation } from './Animation/index';
import { Goals } from './constants/goal';
import { CreditScoreData } from './constants/creditScoreData';
import BackButton from './Button/BackButton';
import Selected from './selected/Selected';
import CurrencyInput from './inputs/currencyInput';
type Props = {
  goal: string;
  creditScore: string;
  homeWorthval: string;
  setGoal: React.Dispatch<React.SetStateAction<string>>;
  setCreditScore: React.Dispatch<React.SetStateAction<string>>;
  setopenComponent: React.Dispatch<React.SetStateAction<number>>;
  setHomeWorthVal: React.Dispatch<string>;
  setMortageBal: React.Dispatch<string>;
  mortageBal: string;
};

function FivePageCompoTwo({
  setopenComponent,
  setGoal,
  goal,
  creditScore,
  setCreditScore,
  homeWorthval,
  setHomeWorthVal,
  setMortageBal,
  mortageBal
}: Props) {
  const [errValWorth, setErrValWorth] = useState('');
  const [errValBalance, setErrValBalance] = useState('');
  const [gaolRequired, setGoalRequired] = useState(false);
  const [mortageBalRequired, setMortageBalRequired] = useState(false);
  const [homeWorthvalRequired, setHomeWorthValRequired] = useState(false);
  const [creditScoreRequired, setCreditScoreRequired] = useState(false);
  const [requiredError, setRequiredError] = useState([false, false, false, false]);

  useEffect(() => {
    if (goal != '' && goal != undefined) {
      setGoalRequired(false);
    }
    if (creditScore != '' && creditScore != undefined) {
      setCreditScoreRequired(false);
    }
    if (homeWorthval != undefined && homeWorthval != '') {
      setHomeWorthValRequired(false);
    }
    if (mortageBal != '' && mortageBal != undefined) {
      setMortageBalRequired(false);
    }
  }, [goal, creditScore, homeWorthval, mortageBal]);

  const inputs = document.querySelectorAll("input");
	inputs.forEach((input) => {
	  input.addEventListener("keypress", (e) => {	
		if (e.keyCode === 13) {
		  e.preventDefault();
		  const currentTabIndex = input.tabIndex;
		  const nextInput = document.querySelector(
			`input[tabIndex="${currentTabIndex + 1}"]`
		  );
		  if (nextInput) {
	     (nextInput as HTMLInputElement).focus();
		  }
		}
	  });
	});

  function handleOnSend() {
    if (goal == '' || goal == undefined) {
      setGoalRequired(true);
    } else {
      console.log('run');
      setGoalRequired(false);
    }

    if (mortageBal == '' || mortageBal == undefined) {
      setMortageBalRequired(true);
    } else {
      setMortageBalRequired(false);
    }

    if (homeWorthval == '' || homeWorthval == undefined) {
      setHomeWorthValRequired(true);
    } else {
      setHomeWorthValRequired(false);
    }
    if (creditScore == '' || creditScore == undefined) {
      setCreditScoreRequired(true);
    } else {
      setCreditScoreRequired(false);
    }

    if (
      goal != '' &&
      goal != undefined &&
      creditScore != '' &&
      creditScore != undefined &&
      homeWorthval != undefined &&
      homeWorthval != '' &&
      mortageBal != '' &&
      mortageBal != undefined
    ) {
      setopenComponent(3);
      window.scrollTo({ top: 0,  behavior: 'smooth' });
      return;
    }
    
  }

  return (
    <div className="mt-10">
      <BackButton setopenComponent={setopenComponent} id={1} />
      <div className="flex flex-col gap-20 mt-10">
      <motion.div
            variants={textanimation}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
          > <p className="font-raleway mx-2 sm:mx-0 text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center font-black">
          Get a free<span className="text-brand"> home equity </span>
          report
        </p> </motion.div>
        <div className="flex flex-col gap-12">
          <div>
            <div className="flex flex-col gap-6">
              <p className="text-xl font-semibold">What is your goal?</p>

              <Selected
                textState={goal}
                setTextState={setGoal}
                DataArray={Goals}
                tabIndex={1}
                thumbnail="Lower my monthly payments"
              />
            </div>
            {gaolRequired && (
              <span className="text-red-700 text-center sm:w-[65%] mx-auto  text-[10px] sm:text-[12px] font-semibold">
                Required
              </span>
            )}
          </div>

          <div className="flex max-lg:flex-col gap-12 md:gap-10 xl:gap-20">
            <div>
              <div className="flex flex-col gap-6">
                <p className="text-xl font-semibold">
                  What is your home worth? (you can guess, it's OK)
                </p>
                <CurrencyInput
                  textState={homeWorthval}
                  tabIndex={2}
                  setTextState={setHomeWorthVal}
                  errVal={errValWorth}
                  setErrVal={setErrValWorth}
                />{' '}
              </div>
              {mortageBalRequired && (
                <span className="text-red-700 text-center sm:w-[65%] mx-auto  text-[10px] sm:text-[12px] font-semibold">
                  Required
                </span>
              )}
            </div>
            <div>
              <div className="flex flex-col gap-6">
                <p className="text-xl font-semibold">What is your mortgage balance?</p>

                <CurrencyInput
                  textState={mortageBal}
                  tabIndex={3}
                  setTextState={setMortageBal}
                  errVal={errValBalance}
                  setErrVal={setErrValBalance}
                />
              </div>
              {homeWorthvalRequired && (
                <span className="text-red-700 text-center sm:w-[65%] mx-auto  text-[10px] sm:text-[12px] font-semibold">
                  Required
                </span>
              )}
            </div>
          </div>
          <div>
            <div className="flex flex-col gap-6">
              <p className="text-xl font-semibold">Do you know current credit score? (it's OK if you don't, we'll give you your free score in your report)</p>
              <Selected
                textState={creditScore}
                tabIndex={4}
                setTextState={setCreditScore}
                DataArray={CreditScoreData}
                thumbnail="Very Good (720 +)"
              />
            </div>
            {creditScoreRequired && (
              <span className="text-red-700 text-center sm:w-[65%] mx-auto  text-[10px] sm:text-[12px] font-semibold">
                Required
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-center items-center w-full mb-10">
          <button
            onClick={() => {
              handleOnSend();
            }}
            id='page11_btn_next1'
            className={`px-20 sm:px-36 py-2 sm:py-4 bg-brand border-brand border-2 hover:bg-white hover:text-brand text-white rounded-full w-fit text-lg font-bold `}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default FivePageCompoTwo;
