import React from 'react';
import LogoGoogle from '../../../../assets/logos_google.svg';
import { StarIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import { cardanimation } from '../Animation/index';

type Props = {
  Name: string;
  image: string;
  content: string;
};

const Card = ({ image, Name, content }: Props) => {
  return (
    <>
      <motion.div
        variants={cardanimation}
        initial="offscreen"
        whileInView="onscreen"
        className="shadow-xl  border-2 rounded-2xl p-4 my-8  "
      >
        <div className="flex  justify-between">
          <div className="flex">
            <img
              className="mt-1 rounded-full border-black  shadow-sm w-10 h-10"
              src={image}
              alt="user1"
            />
            <div className="text-center ml-3">
              <p className="text-lg ">{Name}</p>
              <span className="stars flex text-yellow-400">{renderStar(5)}</span>
            </div>
          </div>
          <img className="h-6 md:h-8 lg:h-10 opacity-50" src={LogoGoogle} alt="Google Logo" />
        </div>
        <div className="opacity-60">
          <p className="py-2">
            <b>Positive: </b> Professionalism, Responsiveness
          </p>
          <p className="text-sm">{content}</p>
        </div>
      </motion.div>
    </>
  );
};

export default Card;

const renderStar = (count: number) => {
  const output: JSX.Element[] = [];

  for (let i = 0; i < count; i++) {
    output.push(<StarIcon key={`star-${i}`} className="w-4  lg:w-5" />);
  }

  return output;
};
