import React from 'react';

interface Props {
  setTextState: React.Dispatch<string>;
  textState: string;
  setErrVal: React.Dispatch<React.SetStateAction<string>>;
  errVal: string;
  tabIndex:number
}

function CurrencyInput({ setTextState, textState, setErrVal, errVal, tabIndex }: Props) {
  return (
    <div className="w-fit">
      <div className="flex flex-row bg-white rounded-full  border-2 justify-center items-center w-fit ">
        <div className=" bg-brand ml rounded-full font-sans text-lg text-white px-6 min-[360px]:px-8 sm:px-10 md:px-16 py-2  ">
          $
        </div>
        <input
          className="outline-none rounded-full font-sans text-lg pl-2 min-[360px]:pl-4 md:px-8 xl:px-16 py-2 "
          type="text"
          placeholder="e.g-250,000"
          value={textState}
          tabIndex={tabIndex}
          onChange={(e) => {
            setTextState(e.target.value);
            setErrVal('');
          }}
        />
      </div>
      <span className="text-red-700 text-center sm:w-[65%] mx-auto my-4 text-[10px] sm:text-[12px] font-semibold">
        {errVal}
      </span>
    </div>
  );
}

export default CurrencyInput;
