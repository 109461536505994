import { useState } from 'react';
import downArrow from '../../../../assets/page11/downArrow.svg';

interface Props {
  textState: string;
  DataArray: {text:string,id:string}[];
  thumbnail: string;
  tabIndex:number
  setTextState: React.Dispatch<React.SetStateAction<string>>;
}
function Selected({ textState, setTextState, DataArray, thumbnail, tabIndex }: Props) {
  const [selectedIsOpen, setSelectedIsOpen] = useState(false);

  return (
    <div id="selected" className="flex flex-col gap-6">
      <div
        onClick={() => {
          setSelectedIsOpen(!selectedIsOpen);
        }}
        tabIndex={tabIndex}
        className="relative flex justify-between items-center gap-1 sm:gap-3 rounded-lg border-brand border-2 w-full sm:w-fit px-4 py-1 sm:min-w-[350px] cursor-pointer"
      >
        <p className="text-brand font-bold text-lg leading-loose">
          {textState == undefined || textState == '' ? thumbnail : textState}
        </p>
        <img
          src={downArrow}
          alt="down arrow"
          className={`${selectedIsOpen && 'rotate-180'} transition-all duration-300`}
        />

        <div
          id="options"
          className={`absolute w-full left-0 bg-white/95 shadow-lg border-1 border-brand top-[105%] transition-all duration-300 text-brand *:border-b *:cursor-pointer  flex flex-col justify-around *:py-[6px] *:px-2 z-50 px-3 overflow-hidden ${
            selectedIsOpen ? 'h-52 opacity-1' : 'h-0 opacity-0 '
          }`}
        >
          {DataArray.map((data) => {
            return (
              <div
              id={data.id}
              className='hover:bg-brand hover:text-white'
                onClick={() => {
                  console.log(data);
                  setTextState(data.text);
                }}
              >
                {data.text}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Selected;
